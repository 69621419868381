#root,
body,
html {
  height: 100%;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(199, 199, 199, 0.75);
  border-radius: 5px;
}