

#amp-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    overflow: auto;
    width: 300px;
    z-index: 999;
    border-left: 1px solid #eaeaea;
    background: #fff;
}

#amp-searchBar {
    height: 30px;
    background: #ccc;
}

#amp-searchInput {
    width: 100%;
    height: 30px;
    line-height: 30%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0 5px;
}
#amp-searchInput:focus{
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}

#amp-searchResults {
    overflow: auto;
    height: calc(100% - 30px);
}

.amap_lib_placeSearch,
.amap-ui-poi-picker-sugg-container {
    border: none!important;
}

.amap_lib_placeSearch .poibox.highlight {
    background-color: #CAE1FF;
}

.poi-more {
    display: none!important;
}