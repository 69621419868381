.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fafafa;
}

.container {
  width: 420px;
  /* margin-top: 200px; */
  padding: 48px;
  background-color: #fff;
}

.header {
  text-align: center;
}

.logo {
  width: 56px;
}

.title {
  margin-top: 20px;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 16px;
  letter-spacing: 2px;
}

.form {
  margin-top: 32px;
}

.footer {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .page {
    background-color: #fff;
  }
  .container {
    width: 100%;
    max-width: 400px;
  }
}
