@import '~antd/dist/antd.css';

.ant-layout {
  min-height: 100%;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
}

.ant-layout-header {
  height: 48px;
  line-height: 48px;
  background: #fff;
}

.page-spining {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66vh;
}

.page-content {
  margin: 16px;
  background-color: #fff;
  position: absolute;
  left:0;
  right: 0;
  top:48px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.logo img {
  width: 32px;
  height: 32px;
}

.logo h1 {
  margin: 0 0 0 12px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.ant-form-inline .ant-form-item {
  margin-bottom: 16px;
}
